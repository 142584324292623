<template>

  <div class="page-layout__content">
    <div class="row">
      <div class="col-md-7">
        <CForm>

          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('title') }} <span class="required">*</span>
            </label>
            <input type="text" class="field" v-model="form.title" :class="{'is-invalid':$v.form.title.$error}"
                   :placeholder="getTranslation('title')">
          </div>

          <div class="row">

            <CountrySelect :form-label="'originCountry'" required :add-class="'col-6'" v-model="form.origin_country_id"/>

            <div class="form-group col-6">
              <label class="form-check-label form__label">
                {{ getTranslation('transportation') }}
              </label>
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" v-model="form.by_land" id="defaultCheck1">
                <label class="form-check-label form__label" for="defaultCheck1">
                  {{ getTranslation('isByLand') }}
                </label>
              </div>
            </div>
            <div class="form-group col-12">
              <label class="form__label form__label--block">{{ getTranslation('originAddress') }}<span class="required">*</span></label>
              <textarea class="form-control field" :class="{'is-invalid':$v.form.address_origin.$error}" rows="2" v-model="form.address_origin"></textarea>
            </div>
          </div>

          <div class="row">

            <CountrySelect :form-label="'destinationCountry'" required :add-class="'col-6'" v-model="form.destination_country_id"/>

            <CitySelect v-if="showCities" :add-class="'col-6'" :form-label="'destinationCity'" :country-id="form.destination_country_id" v-model="form.destination_city_id"/>

            <AirportSelect v-if="showAirports && !form.by_land" :add-class="'col-6'" :form-label="'destinationAirport'" v-model="form.destination_airport_id"/>

            <div class="form-group col-12">
              <label class="form__label form__label--block">{{ getTranslation('destinationAddress') }}<span
                  class="required">*</span></label>
              <textarea class="form-control field" :class="{'is-invalid':$v.form.address_destination.$error}" rows="2" v-model="form.address_destination"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-6">
              <label class="form__label form__label--block">{{ getTranslation('shipmentDate') }}<span
                  class="required">*</span></label>
              <DatePicker
                  v-model="form.shipment_date"
                  :required="true"
                  :error="$v.form.shipment_date.$error"
                  :format="'YYYY-MM-DD'"
                  :display-format="'DD.MM.YYYY'"
              />
            </div>
          </div>

          <div class="row">
            <div class="form-group col-12">
              <label class="form__label form__label--block">{{ getTranslation('serviceCategory') }} <span
                  class="required">*</span></label>
              <VSelect
                  :options="services"
                  :class="{'is-invalid':$v.form.service_category_id.$error}"
                  label="title"
                  :reduce="option=>option.id"
                  v-model="form.service_category_id"
                  :placeholder="getTranslation('selectFromList')"
              />
            </div>
          </div>

          <div class="form-row mt-3">
            <label class="form__label form__label--block" >{{ getTranslation('serviceOptions') }} <span class="required">*</span></label>
            <div v-for="service in serviceOptions" class="col-md-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" :class="{'is-invalid':$v.form.service_option_id.$error}" v-model="form.service_option_id" :value="service.id"
                       :id="service.id">
                <label class="form-check-label" :for="service.id">
                  {{ getTranslation(form.by_land ? service.title.land : service.title.air) }}
                </label>
              </div>
            </div>
          </div>

          <!-- specialty -->
          <div v-if="form.service_category_id === SPECIALTY_ID" class="mt-4">
            <div v-if="showServiceParameters" class="form-row">
              <label class="form__label form__label--block">{{ getTranslation("Please select what you'd like to ship:") }} <span
                  class="required">*</span></label>
              <div v-for="(type,key) in serviceParameters.types" v-bind:key="key" class="col-md-6 col-sm-12">
                <div class="form-group">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="specialtyType" :id="type.slug" :value="type.slug" v-model="specialtyType">
                    <label class="form-check-label" :for="type.slug">
                      {{ getTranslation(type.title) }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- move on -->
          <div v-if="form.service_category_id === HOME_MOVE_ID" class="mt-4">
            <div class="form-group">
              <label class="form__label form__label--block">{{ getTranslation("Leave a request") }}</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="callRequest" v-model="form.is_call_requested">
                <label class="form-check-label" for="callRequest">
                  {{ getTranslation("Leave a request") }}
                </label>
              </div>
            </div>
          </div>

          <div v-if="!form.is_call_requested && form.service_category_id !== DANGEROUS_GOODS_ID">
            <label class="form__label mt-4">{{ getTranslation('parcels') }} <span class="required">*</span></label>
            <a href="javascript:void(0)" :title="getTranslation('add')" class="btn  btn--system btn--white btn-sm"
               @click="addParcel(form.service_category_id)">
              <svg class="btn__icon  btn__icon--sm">
                <use xlink:href="#icon__square-add"></use>
              </svg>
            </a>
          </div>

          <div v-if="DANGEROUS_GOODS_ID === form.service_category_id" class="form-group mt-3">
            <label class="form__label form__label--block" for="shortArea">{{ getTranslation('dangerousGoodsDescription') }}</label>
            <textarea v-model.trim="form.description" class="field  field--full-width  field--short-area" name="" id="shortArea" cols="30" rows="10"/>
          </div>

          <div v-if="specialtyType === 'specialty_human_remains'">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-row" v-if="showServiceParameters">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="form__label form__label--block">{{ getTranslation('typeOfRemains') }}
                        <span class="required">*</span></label>
                      <VSelect
                          :options="serviceParameters.options"
                          label="title"
                          v-model="parcel.parcel_type_id"
                          :reduce="option=>option.id"
                          :placeholder="getTranslation('selectFromList')"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="form__label form__label--block">{{ getTranslation('airContainerType') }}
                        <span class="required">*</span></label>
                      <VSelect
                          :options="serviceParameters.airContainerTypes"
                          label="title"
                          :reduce="option=>option.id"
                          v-model="parcel.container_type_id"
                          :placeholder="getTranslation('selectFromList')"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
              </CCardBody>
            </CCard>

          </div>

          <div v-if="specialtyType === 'specialty_valuables'">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-row" v-if="showServiceParameters">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="form__label form__label--block">{{ getTranslation('typeOfRemains') }}
                        <span class="required">*</span></label>
                      <VSelect
                          :options="serviceParameters.valuableTypes"
                          label="title"
                          v-model="parcel.parcel_type_id"
                          :reduce="option=>option.id"
                          :placeholder="getTranslation('selectFromList')"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
              </CCardBody>
            </CCard>

          </div>

          <div v-if="specialtyType === 'specialty_vehicles' || specialtyType==='specialty_fashion'">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div v-if="specialtyType===50" class="form-row mt-3">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label class="form__label">{{ getTranslation('vehicleWheelBase') }}</label>
                      <input type="number" min="0" class="field" v-model="parcel.wheel_base"/>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" disabled="disabled" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
              </CCardBody>
            </CCard>

          </div>

          <div v-if="form.service_category_id === GENERAL_CARGO_ID || form.service_category_id === PARCELS_ID || form.service_category_id===HOME_MOVE_ID">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group" v-if="form.service_category_id===HOME_MOVE_ID">
                  <label class="form__label">{{ getTranslation('title') }}</label>
                  <input type="text" class="field" v-model="parcel.title"/>
                </div>
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>
                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </div>

          <!-- pets -->
          <div v-if="form.service_category_id === PETS_LIVE_ID">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('animalKindToBook') }} <span
                      class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.options"
                      label="title"
                      v-model="parcel.animal_kind"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>
                <div v-if="parcel.animal_kind.children.length > 0" class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('animalTypeToBook') }} <span
                      class="required">*</span></label>
                  <VSelect
                      :options="parcel.animal_kind.children"
                      label="title"
                      :reduce="option=>option.id"
                      v-model="parcel.animal_type_id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('breed') }}</label>
                  <input type="text" class="field" v-model="parcel.title"/>
                </div>
                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>
                <div class="form-group">
                  <div class="form-check mt-3">
                    <input class="form-check-input" type="checkbox" v-model="parcel.is_household" id="household">
                    <label class="form-check-label form__label" for="household">
                      {{ getTranslation('isHousehold') }}
                    </label>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-3 col-sm-6">
                    <label class="form__label">{{ getTranslation('age') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.age"/>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.age_unit_id">
                      <option v-for="unit in unitOptions.age" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
              </CCardBody>
            </CCard>
          </div>

          <!-- perishables -->
          <div v-if="form.service_category_id === PERISHABLES_ID">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('perishableProduct') }} <span
                      class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.options"
                      label="title"
                      v-model="parcel.parcel_type"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>
                <div v-if="parcel.parcel_type.children.length > 0" class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('commodity') }} <span
                      class="required">*</span></label>
                  <VSelect
                      :options="parcel.parcel_type.children"
                      label="title"
                      :reduce="option=>option.id"
                      v-model="parcel.parcel_type_id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>

                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>

              </CCardBody>
            </CCard>
          </div>

          <!-- pharma -->
          <div v-if="form.service_category_id === PHARMA_ID">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('pharmaProduct') }} <span
                      class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.options"
                      label="title"
                      v-model="parcel.parcel_type_id"
                      :reduce="option=>option.id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>

                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>

              </CCardBody>
            </CCard>
          </div>

          <!-- medical -->
          <div v-if="form.service_category_id === MEDICAL_ID">
            <CCard v-for="(parcel,key) in parcels" v-bind:key="key" class="mt-3">
              <CCardHeader>
                <span class="card-title font-2xl"> № {{ key + 1 }}</span>
                <div class="card-header-actions">
                  <button @click="removeParcel(key)" type="button" class="btn btn-danger"
                          :disabled="parcels.length < 2">
                    <CIcon name="cil-delete"/>
                  </button>
                </div>
              </CCardHeader>
              <CCardBody>
                <div class="form-group">
                  <label class="form__label form__label--block">{{ getTranslation('medicalProduct') }} <span
                      class="required">*</span></label>
                  <VSelect
                      :options="serviceParameters.options"
                      label="title"
                      v-model="parcel.parcel_type_id"
                      :reduce="option=>option.id"
                      :placeholder="getTranslation('selectFromList')"
                  />
                </div>

                <div class="form-group">
                  <label class="form__label">{{ getTranslation('quantity') }}</label>
                  <input type="number" min="0" class="field" v-model="parcel.quantity"/>
                </div>

                <div class="form-row">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('length') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.length"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('width') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.width"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('height') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.height"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.dimension_unit_id">
                      <option v-for="unit in unitOptions.dimension" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('weight') }}</label>
                    <input type="number" min="0" step="any" class="field" v-model="parcel.weight"/>
                  </div>
                  <div class="col-3">
                    <label class="form__label">{{ getTranslation('units') }}</label>
                    <select class="field field--select form-control-sm" v-model="parcel.weight_unit_id">
                      <option v-for="unit in unitOptions.weight" :value="unit.id">{{ unit.title }}</option>
                    </select>
                  </div>
                </div>

              </CCardBody>
            </CCard>
          </div>

        </CForm>
      </div>

      <!--    contact part   -->
      <div class="col-md-5">
        <CForm>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('contactName') }} <span
                class="required">*</span></label>
            <input type="tel" class="field" v-model="form.contact.name" :class="{'is-invalid':$v.form.contact.name.$error}">
          </div>
          <div class="form-row">
            <div class="form-group col-md-6 col-sm-12">
              <label class="form__label--block form__label">{{ getTranslation('mobile') }} <span
                  class="required">*</span></label>
              <input type="tel" class="field" v-model="form.contact.mobile" :class="{'is-invalid':$v.form.contact.mobile.$error}">
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <label class="form__label--block form__label">{{ getTranslation('whatsapp') }}</label>
              <input type="tel" class="field" v-model="form.contact.whatsapp">
            </div>
          </div>
          <div class="form-group">
            <label class="form__label--block form__label">{{ getTranslation('email') }} <span class="required">*</span></label>
            <input type="email" class="field" :class="{'is-invalid':$v.form.contact.email.$error}" v-model="form.contact.email">
          </div>

          <label class="form-check-label form__label mt-5" for="borderService">
            {{ getTranslation('borderServices') }}
          </label>
          <div class="form-check mt-3">
            <input class="form-check-input" type="checkbox" v-model="form.needs_border_service" id="borderService">
            <label class="form-check-label form__label" for="borderService">
              {{ getTranslation('needBorderService') }}
            </label>
            <p class="text-muted">
              {{ getTranslation('customsServiceDocsDescription') }}
            </p>
          </div>
          <div v-if="form.needs_border_service" class="card" style="width: 30rem;">
            <ul class="list-group list-group-flush">
              <li v-for="(document,key) in customsDocuments" class="list-group-item">
                <span v-if="key !== 3">{{ key + 1 }}) </span>{{ document.title }}
              </li>
            </ul>
          </div>

        </CForm>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-8 form-group">
        <button type="submit" @click="saveApplication" class="btn btn-primary">{{ getTranslation('save') }}</button>
        <button type="button" @click="()=>{$router.push({name:'application.getList'})}" class="btn btn-outline-dark ml-3">
          {{ getTranslation('cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import applicationActionMixin from "@/views/admin/applications/mixins/applicationAction";
import generalMixin from "@/components/mixins/General";
import LightBox from 'vue-image-lightbox'
import CountrySelect from "@/components/shared/CountrySelect";
import CitySelect from "@/components/shared/CitySelect";
import AirportSelect from "@/components/shared/AirportSelect";

export default {
  name: "ApplicationCreate",
  components: { AirportSelect, CitySelect, CountrySelect, LightBox },
  mixins: [ applicationActionMixin, generalMixin ],
  data() {
    return {
      form: {
        title: '',
        service_category_id: '',
        origin_country_id: '',
        destination_country_id: '',
        destination_airport_id: '',
        destination_city_id: '',
        address_origin: '',
        address_destination: '',
        shipment_date: '',
        parcel_count: 1,
        parcel_weight: 0,
        weight_unit_id: '',
        total_price: 0,
        by_land: false,
        needs_border_service: false,
        is_call_requested: false,
        service_option_id: '',
        description: '',
        parcels: [],
        contact: {
          name: '',
          type: 1,
          email: '',
          mobile: '',
          whatsapp: '',
        }
      },
      specialtyType: '',
      showAirports: false,
      showCities: false,
      showServiceParameters: false,

      file: {},

      parcels: [],
      documents: [ {
        title: '',
        description: '',
        type_id: '',
        files: []
      } ],

      document: {
        title: '',
        description: '',
        type_id: '',
        files: []
      },
      parcelForms: {
        specialty: {
          parcel_type_id: '',
          container_type_id: '',
          width: '',
          height: '',
          length: '',
          weight: '',
          quantity: 1,
          wheel_base: '',
          weight_unit_id: '',
          dimension_unit_id: '',
        },
        perishable: {
          parcel_type_id: '',
          parcel_type: {
            children: []
          },
          width: '',
          height: '',
          length: '',
          weight: '',
          quantity: 1,
          weight_unit_id: '',
          dimension_unit_id: '',
        },
        pharma: {
          parcel_type_id: '',
          width: '',
          height: '',
          length: '',
          weight: '',
          quantity: 1,
          weight_unit_id: '',
          dimension_unit_id: '',
        },
        medical: {
          parcel_type_id: '',
          width: '',
          height: '',
          length: '',
          weight: '',
          quantity: 1,
          weight_unit_id: '',
          dimension_unit_id: '',
        },
        generalCargo: {
          title: '',
          width: '',
          height: '',
          length: '',
          weight: '',
          quantity: 1,
          weight_unit_id: '',
          dimension_unit_id: '',
        },
        petsLive: {
          title: '',
          age: '',
          quantity: 1,
          weight: 0,
          animal_type_id: '',
          animal_kind_id: '',
          animal_kind: {
            children: []
          },
          weight_unit_id: '',
          age_unit_id: '',
          is_household: false,
          width: '',
          height: '',
          length: '',
          dimension_unit_id: '',
        }
      }

    }
  },

  created() {
    this.setHeadings({
      title: this.getTranslation('addingApplication'),
      toBack: {
        label: this.getTranslation('toBack',this.getTranslation('applications')),
        route: { name: 'application.getList' }
      },
      breadcrumbs: [
        {
          text: this.getTranslation('applications'),
          to: { name: 'application.getList' }
        },
        {
          text: this.getTranslation('addingApplication')
        }
      ]
    });

    this.fetchServiceOptions();
    this.fetchServices();
    this.fetchCountries();
    this.fetchCustomsDocuments();
    this.fetchUnitOptions();

  },
  watch: {
    'form.is_call_requested'(val) {
      if ( val )
        this.parcels = [];
      else
        this.addParcel(this.form.service_category_id);
    },
    'specialtyType'(val) {

      this.parcels = [];
      this.addParcel(this.form.service_category_id);

      if ( val === 'specialty_vehicles' || val === 'specialty_fashion' ) {
        this.parcels.map(item => {
          item.parcel_type_id = val;
          return item;
        })
      }
    },
    'form.destination_country_id'(val) {
      this.showAirports = false;
      this.showCities = false;
      this.form.destination_airport_id = '';
      this.form.destination_city_id = '';

      if ( val )
      {
        this.showCities = true;
      }
    },
    'form.service_category_id'(val) {
      this.parcels = [];
      if ( val )
        this.fetchServiceParameters(val).then(() => {
          this.addParcel(val);
          this.showServiceParameters = true;
        });
    },
    'form.needs_border_service'(val) {
      if ( val )
        this.fetchCustomsDocuments();
    },
  },

  computed: {
    parcelCount() {
      let sum = 0;
      for ( const p of this.parcels ) {
        sum += parseInt(p.quantity);
      }

      return sum;
    }
  },

  methods: {


    saveApplication() {

      this.form.parcels = this.parcels;

      this.$v.$touch();

      if ( this.$v.form.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.application.applications.url, this.form)
          .then(response => {
            this.snotifySuccess('saveSuccessMsg');
            this.$router.push({ name: 'application.get', params: { id: response.data.data.id } });
          }).catch(()=>{});
    },


    openGallery(index, key = 0) {
      if ( this.$refs && typeof this.$refs.lightBoxGallery !== 'undefined' ) {
        this.$nextTick(() => {
          this.$refs.lightBoxGallery[key].showImage(index)
        })
      }


    },
    addDocument() {
      this.documents.push({
        title: '',
        description: '',
        type_id: '',
        files: []
      });
    },
    removeDocument(index) {

      if ( this.documents.length <= index || index < 0 )
        return;

      this.documents.splice(index, 1);
    },
    addParcel(serviceCategoryId) {
      switch ( serviceCategoryId ) {

        case this.GENERAL_CARGO_ID:
        case this.PARCELS_ID:
        case this.HOME_MOVE_ID:
          this.parcels.push({ ...this.parcelForms.generalCargo });
          break;

        case this.PETS_LIVE_ID:
          this.parcels.push({ ...this.parcelForms.petsLive });
          break;

        case this.PERISHABLES_ID:
          this.parcels.push({ ...this.parcelForms.perishable });
          break;

        case this.PHARMA_ID:
          this.parcels.push({ ...this.parcelForms.pharma });
          break;
        case this.MEDICAL_ID:
          this.parcels.push({ ...this.parcelForms.medical });
          break;

        case this.SPECIALTY_ID:

          let parcel = { ...this.parcelForms.specialty };

          if ( this.specialtyType === 'specialty_vehicles' || this.specialtyType === 'specialty_fashion' )
            parcel.parcel_type_id = this.specialtyType;


          this.parcels.push(parcel);
          break;
      }
    },

    removeParcel(index) {

      if ( this.parcels.length <= index || index < 0 )
        return;

      if ( this.parcels.length < 2 )
        return;

      Swal.fire({
        title: this.getTranslation('areYouSure'),
        text: this.getTranslation('YouCantRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.getTranslation('yes')
      }).then(result => {

        if ( result.isConfirmed ) {

          this.parcels.splice(index, 1);

        }

      })
    },

    uploadFile(e) {
      if ( e.target.files.length > 0 && e.target.files[0] ) {
        this.file = e.target.files[0];

        let form = new FormData;

        form.append('file', this.file);

        this.axios.post('/upload', form, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${ form.boundary }`
          }
        }).then(response => {

        })
      }
    },

    async uploadPhoto(e, index) {
      if ( e.target.files.length > 0 ) {

        let file = e.target.files[0];

        await this.getImageInfo(file).then(imageInfo => {
          this.documents[index].files.push(imageInfo);
        });
      } else
        this.snotifyError('fileFormatErrorMsg', '.png,.jpeg,...');
    },

  },
  validations() {
    if ( this.form.service_category_id === this.BORDER_SERVICES || this.form.service_category_id === this.HOME_MOVE_ID || this.form.service_category_id === this.DANGEROUS_GOODS_ID ) {
      return {
        form: {
          title: {
            required
          },
          service_category_id: {
            required
          },
          origin_country_id: {
            required
          },
          service_option_id: {
            required
          },
          destination_country_id: {
            required
          },
          address_origin: {
            required
          },
          address_destination: {
            required
          },
          shipment_date: {
            required
          },
          by_land: {
            required
          },
          needs_border_service: {
            required
          },
          contact: {
            mobile: {
              required
            },
            email: {
              required
            },
            name: {
              required
            },
            type: {
              required
            }
          }
        }
      }
    } else {
      return {
        form: {
          title: {
            required
          },
          service_category_id: {
            required
          },
          origin_country_id: {
            required
          },
          service_option_id: {
            required
          },
          destination_country_id: {
            required
          },
          address_origin: {
            required
          },
          address_destination: {
            required
          },
          shipment_date: {
            required
          },
          by_land: {
            required
          },
          needs_border_service: {
            required
          },
          parcels: {
            required
          },
          contact: {
            mobile: {
              required
            },
            email: {
              required
            },
            name: {
              required
            },
            type: {
              required
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>